<template>
  <div>
    <BCard title="상품 정보">
      <BRow>
        <BCol cols="4">
          <BFormGroup label="IDX">
            <TagbyLinkInput
              :value="product.product_idx_id"
              :to="{name: 'commerce-product-detail', params: {idx: product.product_idx_id}}"
            />
          </BFormGroup>
        </BCol>
        <BCol cols="4">
          <BFormGroup
            label="상품명"
            :class="getUpdatedFieldClass('name')"
          >
            <BFormInput
              :value="product.name"
              disabled
            />
          </BFormGroup>
        </BCol>
        <BCol cols="4">
          <BFormGroup
            label="브랜드명"
            :class="getUpdatedFieldClass('brand_name')"
          >
            <BFormInput
              :value="product.brand_name"
              disabled
            />
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow>
        <BCol cols="4">
          <BFormGroup
            label="카테고리"
            :class="getUpdatedFieldClass('category_idx')"
          >
            <vSelect
              :value="product.category_idx"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="categoryOptions"
              :reduce="option => option.value"
              disabled
            />
          </BFormGroup>
        </BCol>
        <BCol cols="4">
          <BFormGroup
            label="제품번호"
            :class="getUpdatedFieldClass('product_no')"
          >
            <TagbyCopyableInput
              :value="product.product_no"
              disabled
            />
          </BFormGroup>
        </BCol>
        <BCol cols="4">
          <BFormGroup label="태그바이품번">
            <TagbyCopyableInput
              :value="product.tagby_product_no"
              disabled
            />
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow>
        <BCol cols="4">
          <BFormGroup
            label="재고"
            :class="getUpdatedFieldClass('stock')"
          >
            <BInputGroup
              append="개"
              class="input-group-merge"
            >
              <BFormInput
                :value="product.stock"
                disabled
              />
            </BInputGroup>
          </BFormGroup>
        </BCol>
        <BCol cols="4">
          <BFormGroup
            label="판매 타입"
            :class="getUpdatedFieldClass('sell_type')"
          >
            <vSelect
              :value="product.sell_type"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="sellTypeOptions"
              :reduce="option => option.value"
              disabled
            />
          </BFormGroup>
        </BCol>
        <BCol cols="4">
          <BFormGroup
            label="썸네일"
            :class="getUpdatedFieldClass('thumbnail_img_url')"
          >
            <TagbyPopoverImg
              :url="product.thumbnail_img_url"
            />
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow>
        <BCol cols="4">
          <BFormGroup
            label="출시일"
            :class="getUpdatedFieldClass('start_at')"
          >
            <TagbyDatetimeInputV2
              :value="product.start_at"
              :config="{enableTime: true, dateFormat: 'Y-m-d H:i'}"
              disabled
            />
          </BFormGroup>
        </BCol>
        <BCol cols="4">
          <BFormGroup
            label="판매종료일"
            :class="getUpdatedFieldClass('end_at')"
          >
            <TagbyDatetimeInputV2
              :value="product.end_at"
              :config="{enableTime: true, dateFormat: 'Y-m-d H:i'}"
              disabled
            />
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow>
        <BCol>
          <BFormGroup
            label="상세설명"
            :class="getUpdatedFieldClass('detail_html_content')"
          >
            <TagbyEditor
              :value="product.detail_html_content"
              disabled
            />
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow>
        <BCol>
          <BFormGroup
            label="상품정보"
            :class="getUpdatedFieldClass('description')"
          >
            <BFormTextarea
              :value="product.description"
              rows="5"
              disabled
            />
          </BFormGroup>
        </BCol>
      </BRow>
    </BCard>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BButton,
  BFormGroup,
  BFormCheckbox,
  BImg,
  BTooltip,
  BFormTextarea,
} from 'bootstrap-vue'
import { defineComponent, computed } from '@vue/composition-api'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import TagbyCopyableInput from '@/components/TagbyCopyableInput.vue'
import TagbyLinkInput from '@/components/TagbyLinkInput.vue'
import TagbyDatetimeInputV2 from '@/components/TagbyDatetimeInputV2.vue'
import TagbyPopoverImg from '@/components/TagbyPopoverImg.vue'
import TagbyEditor from '@/components/TagbyEditor.vue'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BFormGroup,
    BFormCheckbox,
    BImg,
    BTooltip,
    BFormTextarea,

    vSelect,
    flatPickr,
    TagbyCopyableInput,
    TagbyLinkInput,
    TagbyDatetimeInputV2,
    TagbyPopoverImg,
    TagbyEditor,
  },
  props: {
    product: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Array,
      default: () => ([]),
    },
    categoryOptions: {
      type: Array,
      default: () => ([]),
    },
    beforeProduct: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const sellTypeOptions = [
      { label: '상시판매', value: 'ALWAYS' },
      { label: '모집판매', value: 'RECRUITMENT' },
    ]

    const getUpdatedFieldClass = key => (props.product[key] !== props.beforeProduct[key] ? 'updated-field' : null)

    return {
      sellTypeOptions,
      getUpdatedFieldClass,
    }
  },
})
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
