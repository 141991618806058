<template>
  <div>
    <BCard>
      <BRow>
        <BCol cols="6">
          <BButton
            variant="outline-primary"
            block
            :disabled="!isWait"
            @click="$emit('turnOnApproveModal')"
          >
            승인
          </BButton>

          <BButton
            variant="outline-secondary"
            block
            @click="$emit('turnOnMemo')"
          >
            메모
          </BButton>
        </BCol>

        <BCol cols="6">
          <BButton
            variant="outline-danger"
            block
            :disabled="!isWait"
            @click="$emit('turnOnRejectModal')"
          >
            거부
          </BButton>
        </BCol>
      </BRow>
    </BCard>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'
import { computed } from '@vue/composition-api'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
  },
  props: {
    product: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const isWait = computed(() => props.product.state === 'WAIT')
    return {
      isWait,
    }
  },
}
</script>
