<template>
  <div>
    <BCard
      no-body
    >
      <BCardTitle class="mt-1 ml-1">
        {{ title }}
      </BCardTitle>
      <BTable
        :fields="fields"
        :items="options"
        small
      >
        <template #cell(detail)="data">
          {{ resolveOptionDetail(data.item.options) }}
        </template>
      </BTable>
    </BCard>
  </div>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BTable,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  components: {
    BCard,
    BCardTitle,
    BTable,
    BDropdown,
    BDropdownItem,
  },
  props: {
    title: {
      type: String,
      default: '옵션 정보',
    },
    options: {
      type: Array,
      default: () => ([]),
    },
  },
  setup() {
    const fields = [
      { key: 'detail', label: '옵션상세' },
      { key: 'add_supply_price', label: '추가공급가' },
      { key: 'add_price', label: '추가가격' },
      { key: 'add_tagby_fee', label: '추가태그바이수수료' },
      { key: 'stock', label: '재고수량' },
    ]
    const resolveOptionDetail = options => options.reduce((p, c) => p.concat(`${c.category}:${c.name}`), []).join(', ')
    return {
      fields,
      resolveOptionDetail,
    }
  },
})
</script>
