import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDetail(ctx, { idx }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/product-update/${idx}/`)
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    fetchProductDetail(ctx, { idx }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/product/${idx}/`)
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    fetchTimelines(ctx, { idx }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/product-update/${idx}/timelines/`)
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    fetchCategories() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/product/categories/`)
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    createMemo(ctc, { idx, ...params }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/product-update/${idx}/memo/`, params)
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    approve(ctc, { idx }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/product-update/${idx}/approve/`)
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    reject(ctc, { idx, ...params }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/product-update/${idx}/reject/`, params)
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
  },
}
