<template>
  <div>
    <BCard title="배송 정보">
      <BRow>
        <BCol cols="4">
          <BFormGroup
            label="배송비"
            :class="getUpdatedFieldClass('shipping_fee')"
          >
            <BInputGroup
              append="원"
              class="input-group-merge"
            >
              <BFormInput
                disabled
                :value="product.shipping_fee"
                number
                type="number"
              />
            </BInputGroup>
          </BFormGroup>
        </BCol>
        <BCol cols="4">
          <BFormGroup
            label="무료배송비조건"
            :class="getUpdatedFieldClassFreeCond('gte')"
          >
            <BInputGroup
              append="원 이상"
              class="input-group-merge"
            >
              <BFormInput
                disabled
                :value="shippingFreeCond.gte"
                number
                type="number"
              />
            </BInputGroup>
          </BFormGroup>
        </BCol>
        <BCol cols="4">
          <BFormGroup
            label="최대묶음개수"
            :class="getUpdatedFieldClass('max_bundle_count')"
          >
            <BInputGroup
              append="개"
              class="input-group-merge"
            >
              <BFormInput
                disabled
                :value="product.max_bundle_count"
                number
                type="number"
              />
            </BInputGroup>
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow>
        <BCol cols="4">
          <BFormGroup
            label="합배송그룹"
            :class="getUpdatedFieldClass('shipping_policy_idx_id')"
          >
            <BFormInput
              :value="shippingPolicy.name"
              readonly
            />
          </BFormGroup>
        </BCol>
        <BCol cols="4">
          <BFormGroup
            label="제주추가배송비"
            :class="getUpdatedFieldClassAddCond('jeju')"
          >
            <BInputGroup
              append="원"
              class="input-group-merge"
            >
              <BFormInput
                disabled
                :value="additionalShippingFeeCond.jeju"
                number
                type="number"
                :readonly="product.shipping_policy_idx_id != null"
              />
            </BInputGroup>
          </BFormGroup>
        </BCol>
        <BCol cols="4">
          <BFormGroup
            label="도서산간추가배송비"
            :class="getUpdatedFieldClassAddCond('island_mountain')"
          >
            <BInputGroup
              append="원"
              class="input-group-merge"
            >
              <BFormInput
                disabled
                :value="additionalShippingFeeCond.island_mountain"
                number
                type="number"
                :readonly="product.shipping_policy_idx_id != null"
              />
            </BInputGroup>
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow>
        <BCol cols="4">
          <BFormGroup
            label="배송일"
            :class="getUpdatedFieldClassMaxMin()"
          >
            <TagbyRangeInput
              :value="`${product.min_delivery_days}~${product.max_delivery_days}`"
              disabled
            />
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow>
        <BCol>
          <BFormGroup
            label="배송상세정보"
            :class="getUpdatedFieldClass('delivery_content')"
          >
            <BFormTextarea
              disabled
              :value="product.delivery_content"
              rows="5"
            />
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow>
        <BCol>
          <BFormGroup
            label="환불상세정보"
            :class="getUpdatedFieldClass('refund_content')"
          >
            <BFormTextarea
              disabled
              :value="product.refund_content"
              rows="5"
            />
          </BFormGroup>
        </BCol>
      </BRow>
    </BCard>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BButton,
  BFormGroup,
  BFormCheckbox,
  VBPopover,
  BPopover,
  BImg,
  BTooltip,
  BFormTextarea,
} from 'bootstrap-vue'
import { defineComponent, computed } from '@vue/composition-api'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import TagbyCopyableInput from '@/components/TagbyCopyableInput.vue'
import TagbyLinkInput from '@/components/TagbyLinkInput.vue'
import TagbyFeeInput from '@/components/TagbyFeeInput.vue'
import TagbyRangeInput from '@/components/TagbyRangeInput.vue'
import TagbyDatetimeInputV2 from '@/components/TagbyDatetimeInputV2.vue'
import TagbyPopoverImg from '@/components/TagbyPopoverImg.vue'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BFormGroup,
    BFormCheckbox,
    VBPopover,
    BPopover,
    BImg,
    BTooltip,
    BFormTextarea,

    vSelect,
    flatPickr,
    TagbyCopyableInput,
    TagbyLinkInput,
    TagbyFeeInput,
    TagbyRangeInput,
    TagbyDatetimeInputV2,
    TagbyPopoverImg,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    product: {
      type: Object,
      default: () => ({}),
    },
    shippingFreeCond: {
      type: Object,
      default: () => ({}),
    },
    additionalShippingFeeCond: {
      type: Object,
      default: () => ({}),
    },
    shippingPolicy: {
      type: Object,
      default: () => ({}),
    },
    beforeProduct: {
      type: Object,
      default: () => ({}),
    },
    beforeShippingFreeCond: {
      type: Object,
      default: () => ({}),
    },
    beforeAdditionalShippingFeeCond: {
      type: Object,
      default: () => ({}),
    },
    beforeShippingPolicy: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const getUpdatedFieldClass = key => (props.product[key] !== props.beforeProduct[key] ? 'updated-field' : null)
    const getUpdatedFieldClassFreeCond = key => (props.shippingFreeCond[key] !== props.beforeShippingFreeCond[key] ? 'updated-field' : null)
    const getUpdatedFieldClassAddCond = key => (props.additionalShippingFeeCond[key] !== props.beforeAdditionalShippingFeeCond[key] ? 'updated-field' : null)
    const getUpdatedFieldClassMaxMin = () => (((props.product.min_delivery_days !== props.beforeProduct.min_delivery_days) || (props.product.max_delivery_days !== props.beforeProduct.max_delivery_days)) ? 'updated-field' : null)
    return {
      getUpdatedFieldClass,
      getUpdatedFieldClassFreeCond,
      getUpdatedFieldClassAddCond,
      getUpdatedFieldClassMaxMin,
    }
  },
})
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
