<template>
  <BModal
    :visible="visible"
    title="요청 거부"
    ok-title="확인"
    ok-variant="danger"
    cancel-title="취소"
    cancel-variant="outline-secondary"
    centered
    modal-class="modal-danger"
    @ok="$emit('ok')"
    @hidden="$emit('hidden')"
  >
    <BFormGroup label="피드백">
      <BFormTextarea
        :value="feedback"
        @input="$emit('inputFeedback', $event)"
      />
    </BFormGroup>
  </BModal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormTextarea,
} from 'bootstrap-vue'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  components: {
    BModal,
    BFormGroup,
    BFormTextarea,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    feedback: {
      type: String,
      default: null,
    },
  },
  setup() {
  },
})
</script>
