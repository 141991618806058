<template>
  <div>
    <BCard title="가격 정보">
      <BRow>
        <BCol cols="4">
          <BFormGroup
            label="공급가"
            :class="getUpdatedFieldClass('supply_price')"
          >
            <BInputGroup
              append="원"
              class="input-group-merge"
            >
              <BFormInput
                disabled
                :value="product.supply_price"
                number
                type="number"
              />
            </BInputGroup>
          </BFormGroup>
        </BCol>
        <BCol cols="4">
          <BFormGroup
            label="가격"
            :class="getUpdatedFieldClass('price')"
          >
            <BInputGroup
              append="원"
              class="input-group-merge"
            >
              <BFormInput
                disabled
                :value="product.price"
                number
                type="number"
              />
            </BInputGroup>
          </BFormGroup>
        </BCol>
        <BCol cols="4">
          <BFormGroup
            label="권장소비자가"
            :class="getUpdatedFieldClass('market_price')"
          >
            <BInputGroup
              append="원"
              class="input-group-merge"
            >
              <BFormInput
                disabled
                :value="product.market_price"
                number
                type="number"
              />
            </BInputGroup>
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow>
        <BCol cols="4">
          <BFormGroup label="태그바이수수료율">
            <BInputGroup
              append="%"
              class="input-group-merge"
            >
              <TagbyFeeInput
                disabled
                :value="beforeProduct.tagby_fee_rate"
              />
            </BInputGroup>
          </BFormGroup>
        </BCol>
        <BCol cols="4">
          <BFormGroup label="태그바이수수료">
            <BInputGroup
              id="product_tagby_fee"
              append="원"
              class="input-group-merge"
            >
              <BTooltip
                target="product_tagby_fee"
                triggers="hover"
                variant="info"
              >
                (가격 + 배송비) * (태그바이수수료율 + 0.03)
              </BTooltip>
              <BFormInput
                :value="computedTagbyFee"
                readonly
              />
            </BInputGroup>
          </BFormGroup>
        </BCol>
        <BCol cols="4">
          <BFormGroup label="셀러수수료">
            <BInputGroup
              id="product_inf_fee"
              append="원"
              class="input-group-merge"
            >
              <BTooltip
                target="product_inf_fee"
                triggers="hover"
                variant="info"
              >
                (가격 - 공급가 - 태그바이수수료) * 10/11
              </BTooltip>
              <BFormInput
                :value="computedInfFee"
                readonly
              />
            </BInputGroup>
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow>
        <BCol cols="4">
          <BFormGroup label="영업외 수익">
            <BInputGroup
              append="원"
              class="input-group-merge"
            >
              <BFormInput
                :value="computedEtcIncome"
                readonly
              />
            </BInputGroup>
          </BFormGroup>
        </BCol>
        <BCol cols="4">
          <BFormGroup
            label="면세상품"
            :class="getUpdatedFieldClassVat('is_tax_free')"
          >
            <BFormCheckbox
              disabled
              :checked="vat.is_tax_free"
            />
          </BFormGroup>
        </BCol>
      </BRow>
    </BCard>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BButton,
  BFormGroup,
  BFormCheckbox,
  VBPopover,
  BPopover,
  BImg,
  BTooltip,
} from 'bootstrap-vue'
import { defineComponent, computed } from '@vue/composition-api'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import TagbyCopyableInput from '@/components/TagbyCopyableInput.vue'
import TagbyLinkInput from '@/components/TagbyLinkInput.vue'
import TagbyFeeInput from '@/components/TagbyFeeInput.vue'
import TagbyRangeInput from '@/components/TagbyRangeInput.vue'
import TagbyDatetimeInputV2 from '@/components/TagbyDatetimeInputV2.vue'
import TagbyPopoverImg from '@/components/TagbyPopoverImg.vue'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BFormGroup,
    BFormCheckbox,
    VBPopover,
    BPopover,
    BImg,
    BTooltip,

    vSelect,
    flatPickr,
    TagbyCopyableInput,
    TagbyLinkInput,
    TagbyFeeInput,
    TagbyRangeInput,
    TagbyDatetimeInputV2,
    TagbyPopoverImg,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    product: {
      type: Object,
      default: () => ({}),
    },
    vat: {
      type: Object,
      default: () => ({}),
    },
    beforeProduct: {
      type: Object,
      default: () => ({}),
    },
    beforeVat: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const computedTagbyFee = computed(() => {
      const {
        price,
        shipping_fee: shippingFee,
      } = props.product
      const {
        tagby_fee_rate: tagbyFeeRate,
      } = props.beforeProduct
      const isValid = value => value != null && value !== ''
      if (![price, tagbyFeeRate, shippingFee].reduce((p, c) => p && isValid(c), true)) return NaN
      return Math.round((price + shippingFee) * (tagbyFeeRate + 0.03))
    })

    const computedInfFee = computed(() => {
      const {
        price,
        supply_price: supplyPrice,
      } = props.product
      const isValid = value => value != null && value !== ''
      if (![price, supplyPrice].reduce((p, c) => p && isValid(c), true)) return NaN
      return Math.round((price - supplyPrice - computedTagbyFee.value) * (10 / 11))
    })

    const computedEtcIncome = computed(() => {
      const {
        price,
        supply_price: supplyPrice,
      } = props.product
      return (price - supplyPrice - computedTagbyFee.value - computedInfFee.value)
    })

    const getUpdatedFieldClass = key => (props.product[key] !== props.beforeProduct[key] ? 'updated-field' : null)
    const getUpdatedFieldClassVat = key => (props.vat[key] !== props.beforeVat[key] ? 'updated-field' : null)

    return {
      computedTagbyFee,
      computedInfFee,
      computedEtcIncome,
      getUpdatedFieldClass,
      getUpdatedFieldClassVat,
    }
  },
})
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
